import "@/plugins/axios";

const api = "/customer/api/v1/profile";

export default {
  state: {
    profile: {},
    profileAddresses: [],
    errors: [],
  },
  actions: {
    getProfile(context) {
      return axios.get(`${api}`).then((res) => {
        context.commit("GET_PROFILE", res.data);
      });
    },
    setProfile: (context) => {
      context.commit("GET_PROFILE", {});
    },
    getProfileAddresses(context) {
      return axios.get(`${api}/addresses/`).then((res) => {
        context.commit("GET_PROFILEAAARESSES", res.data);
      });
    },
    postProfileAddresses(context, payload) {
      return axios.post(`${api}/addresses/current`, payload).then((res) => {
        context.commit("GET_PROFILEAAARESSES", res.data);
      });
    },
    setProfileAddresses: (context, payload) => {
      context.commit("GET_PROFILEAAARESSES", []);
    },
    resendEmail(context) {
      return axios.post(`${api}/resend-email-confirmation`).then((res) => {
        context.commit("GET_PROFILE", res.data);
      });
    },
    updateProfile(context, payload) {
      return axios.patch(api, payload).then((res) => {
        context.commit("GET_PROFILE", res.data);
      });
    },
    updatePhone(context, payload) {
      return axios.post(`${api}/phone`, payload);
    },
    updatePhoneOtp(context, payload) {
      return axios.post(`${api}/phone/otp`, payload).then((res) => {
        context.commit("GET_PROFILE", res.data);
      });
    },
    newPasswordProfile(context, payload) {
      return axios.post(`${api}/password`, payload);
    },
  },
  mutations: {
    GET_PROFILE: (state, payload) => {
      state.profile = payload;
      return state.profile;
    },
    GET_PROFILEAAARESSES: (state, payload) => {
      state.profileAddresses = payload;
      return state.profileAddresses;
    },
  },
  getters: {
    profile(state) {
      return state.profile;
    },
    profileAddresses(state) {
      return state.profileAddresses;
    },
  },
};
