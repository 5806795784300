import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/endowment",
      name: "Home",
      meta: {
        layout: "Main",
        auth: true,
      },
      component: () => import("@/views/Home.vue"),
    },
    {
      path: "/endowment/waqf",
      name: "Endowment Document",
      meta: {
        layout: "Main",
        auth: true,
      },
      component: () => import("@/views/EndowmentDoc.vue"),
    },
    {
      path: "/wallet",
      name: "Wallet",
      meta: {
        layout: "Main",
        auth: true,
      },
      component: () => import("@/views/Wallet.vue"),
    },
    {
      path: "/settings",
      name: "Settings",
      meta: {
        layout: "Main",
        auth: true,
      },
      component: () => import("@/views/Settings.vue"),
    },
    {
      path: "/sign-in",
      name: "Sign in",
      meta: {
        layout: "Empty",
      },
      component: () => import("@/views/Login.vue"),
    },
    {
      path: "/sign-in/confirm",
      name: "Sign in confirm",
      meta: {
        layout: "Empty",
      },
      component: () => import("@/views/LoginConfirm.vue"),
    },
    {
      path: "/sign-up",
      name: "Sign up",
      meta: {
        layout: "Empty",
      },
      component: () => import("@/views/Registration.vue"),
    },
    {
      path: "/sign-up/confirm",
      name: "Sign up confirm",
      meta: {
        layout: "Empty",
      },
      component: () => import("@/views/RegistrationConfirm.vue"),
    },
    {
      path: "/forgot-password",
      name: "Password reset",
      meta: {
        layout: "Empty",
      },
      component: () => import("@/views/PasswordReset.vue"),
    },
    {
      path: "/password-reset-sent",
      name: "Password reset sent",
      meta: {
        layout: "Empty",
      },
      component: () => import("@/views/PasswordResetSent.vue"),
    },
    {
      path: "/password-reset",
      name: "Password recovery",
      meta: {
        layout: "Empty",
      },
      component: () => import("@/views/PasswordRecovery.vue"),
    },
    // {
    //   path: "/account-confirm",
    //   name: "Account confirm",
    //   meta: {
    //     layout: "Empty",
    //   },
    //   component: () => import("@/views/AccountConfirm.vue"),
    // },
    {
      path: "/email-confirmation",
      name: "Email confirmation",
      meta: {
        layout: "Empty",
      },
      component: () => import("@/views/ConfirmEmail.vue"),
    },
    {
      path: "/profile-filling",
      name: "Personal information",
      meta: {
        layout: "Empty",
      },
      component: () => import("@/views/PersonalInfo.vue"),
    },
    {
      path: "*",
      redirect: { name: "Home" },
    },
  ],
  scrollBehavior: (to, from, savedPosition) => {
    if (to.hash) {
      return { selector: to.hash, behavior: "smooth" };
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const currentUser = sessionStorage.getItem("accessToken");
  const requireAuth = to.matched.some((record) => record.meta.auth);
  // const role = sessionStorage.getItem('role');
  // const access = requireAuth && role ? !to.matched.some((record) => record.meta.access.find((i) => i == role)) : null;

  if (requireAuth && !currentUser) {
    next({ name: "Sign in", query: { from: to.fullPath } });
    // } else if (access) {
    //   next('/');
  } else {
    next();
  }
});

export default router;
