import '@/plugins/axios';
import router from '@/router/index';

const api = '/customer/api/v1/endowment';
const api2 = '/customer/api/v2/endowment';

export default {
  state: {
    endowment: {
      owner: {},
      liquidity: {},
      steps: [],
    },
    endowmentTypes: [],
  },
  actions: {
    supportFunction(context, payload) {
      const data = payload;
      const arrayDoc = data.document_date.split('/');
      arrayDoc[1].length == 1 ? (arrayDoc[1] = `0${arrayDoc[1]}`) : null;
      arrayDoc[2].length == 1 ? (arrayDoc[2] = `0${arrayDoc[2]}`) : null;
      data.document_date = arrayDoc.join('-');
      if (data.owner) {
        const arrayOwnerDate = data.owner.date_of_birth.split('/');
        arrayOwnerDate[1].length == 1 ? (arrayOwnerDate[1] = `0${arrayOwnerDate[1]}`) : null;
        arrayOwnerDate[2].length == 1 ? (arrayOwnerDate[2] = `0${arrayOwnerDate[2]}`) : null;
        data.owner.date_of_birth = arrayOwnerDate.join('-');
      }
      if (data.steps) {
        if (data.steps.length) {
          for (let i = 0; i < data.steps.length; i++) {
            if (data.steps[i].sections) {
              for (let j = 0; j < data.steps[i].sections.length; j++) {
                if (data.steps[i].sections[j].type == 'people') {
                  if (!data.steps[i].sections[j].items.length) {
                    setTimeout(() => {
                      data.steps[i].sections[j].items.push({
                        full_name: null,
                        id_number: null,
                        date_of_birth: null,
                        date_of_birth_old: null,
                      });
                    }, 300);
                  } else {
                    for (let l = 0; l < data.steps[i].sections[j].items.length; l++) {
                      const array = data.steps[i].sections[j].items[l].date_of_birth.split('/');
                      array[1].length == 1 ? (array[1] = `0${array[1]}`) : null;
                      array[2].length == 1 ? (array[2] = `0${array[2]}`) : null;
                      data.steps[i].sections[j].items[l].date_of_birth = array.join('-');
                      data.steps[i].sections[j].items[l].date_of_birth_old = array.join('-');
                    }
                  }
                }
                if (data.steps[i].sections[j].type == 'distributions') {
                  if (!data.steps[i].sections[j].items.length) {
                    setTimeout(() => {
                      data.steps[i].sections[j].items.push({ beneficiary: null, details: null });
                    }, 300);
                  }
                }
                if (data.steps[i].sections[j].type == 'list') {
                  if (!data.steps[i].sections[j].items.length) {
                    setTimeout(() => {
                      data.steps[i].sections[j].items.push({ text: null });
                    }, 300);
                  }
                }
              }
            }
          }
        }
      }
      context.commit('GET_ENDOWMENT', data);
    },
    getEndowment(context) {
      return axios.get(`${api}`).then((res) => {
        context.dispatch('supportFunction', res.data);
      });
    },
    setEndowment: (context) => {
      context.commit('GET_ENDOWMENT', {
        owner: {},
        liquidity: {},
        steps: [],
      });
    },
    updateEndowment(context, payload) {
      return axios.patch(`${api}`, payload).then((res) => {
        context.dispatch('supportFunction', res.data);
      });
    },
    actionEndowment(context, payload) {
      return axios.post(`${api2}/action`, payload).then((res) => {
        context.dispatch('supportFunction', res.data);
      });
    },
    addEndowmentWitnesses(context, payload) {
      return axios.post(`${api}/witnesses/`, payload).then((res) => {
        context.dispatch('supportFunction', res.data);
      });
    },
    updateEndowmentWitnesses(context, { id, data }) {
      return axios.put(`${api}/witnesses/${id}`, data).then((res) => {
        context.dispatch('supportFunction', res.data);
      });
    },
    removeEndowmentWitnesses(context, payload) {
      return axios.delete(`${api}/witnesses/${payload}`);
    },
    updateEndowmentWitnessesOrder(context, { id, data }) {
      return axios.post(`${api}/witnesses/${id}/order`, data).then((res) => {
        context.dispatch('supportFunction', res.data);
      });
    },
    addEndowmentAdmins(context, payload) {
      return axios.post(`${api}/admins/`, payload).then((res) => {
        context.dispatch('supportFunction', res.data);
      });
    },
    updateEndowmentAdmins(context, { id, data }) {
      return axios.put(`${api}/admins/${id}`, data).then((res) => {
        context.dispatch('supportFunction', res.data);
      });
    },
    removeEndowmentAdmins(context, payload) {
      return axios.delete(`${api}/admins/${payload}`);
    },
    updateEndowmentAdminsOrder(context, { id, data }) {
      return axios.post(`${api}/admins/${id}/order`, data).then((res) => {
        context.dispatch('supportFunction', res.data);
      });
    },
    addEndowmentResponsibility(context, payload) {
      return axios.post(`${api}/responsibilities/`, payload).then((res) => {
        context.dispatch('supportFunction', res.data);
      });
    },
    updateEndowmentResponsibility(context, { id, data }) {
      return axios.put(`${api}/responsibilities/${id}`, data).then((res) => {
        context.dispatch('supportFunction', res.data);
      });
    },
    removeEndowmentResponsibility(context, payload) {
      return axios.delete(`${api}/responsibilities/${payload}`);
    },
    updateEndowmentResponsibilityOrder(context, { id, data }) {
      return axios.post(`${api}/responsibilities/${id}/order`, data).then((res) => {
        context.dispatch('supportFunction', res.data);
      });
    },
    addEndowmentRSM(context, payload) {
      return axios.post(`${api}/revenue-spending-methods/`, payload).then((res) => {
        context.dispatch('supportFunction', res.data);
      });
    },
    updateEndowmentRSM(context, { id, data }) {
      return axios.put(`${api}/revenue-spending-methods/${id}`, data).then((res) => {
        context.dispatch('supportFunction', res.data);
      });
    },
    removeEndowmentRSM(context, payload) {
      return axios.delete(`${api}/revenue-spending-methods/${payload}`);
    },
    updateEndowmentRSMOrder(context, { id, data }) {
      return axios.post(`${api}/revenue-spending-methods/${id}/order`, data).then((res) => {
        context.dispatch('supportFunction', res.data);
      });
    },
    addEndowmentSpendings(context, payload) {
      return axios.post(`${api}/spendings/`, payload).then((res) => {
        context.dispatch('supportFunction', res.data);
      });
    },
    updateEndowmentSpendings(context, { id, data }) {
      return axios.put(`${api}/spendings/${id}`, data).then((res) => {
        context.dispatch('supportFunction', res.data);
      });
    },
    removeEndowmentSpendings(context, payload) {
      return axios.delete(`${api}/spendings/${payload}`);
    },
    updateEndowmentSpendingsOrder(context, { id, data }) {
      return axios.post(`${api}/spendings/${id}/order`, data).then((res) => {
        context.dispatch('supportFunction', res.data);
      });
    },
    //                              VERSION 2
    getEndowmentTypes(context) {
      return axios.get(`${api2}-types`).then((res) => {
        context.commit('GET_ENDOWMENTTYPES', res.data);
      });
    },
    getEndowmentV2(context) {
      return axios.get(`${api2}`).then((res) => {
        context.dispatch('supportFunction', res.data);
      });
    },
    createEndowment(context, payload) {
      return axios.post(`${api2}`, payload).then(() => {
        router.go(0);
      });
    },
    updateEndowmentV2(context, payload) {
      return axios.put(`${api2}`, payload).then((res) => {
        context.dispatch('supportFunction', res.data);
      });
    },
    actionEndowmentV2(context, payload) {
      return axios.post(`${api2}/action`, payload).then((res) => {
        context.dispatch('supportFunction', res.data);
      });
    },
    updateSectionEndowmentV2(context, { id, data }) {
      return axios.put(`${api2}/sections/${id}`, data).then((res) => {
        context.dispatch('supportFunction', res.data);
      });
    },
    addSectionItemEndowmentV2(context, { id, data }) {
      return axios.post(`${api2}/sections/${id}/items/`, data).then((res) => {
        context.dispatch('supportFunction', res.data);
      });
    },
    updateSectionItemEndowmentV2(context, { id, itemId, data }) {
      return axios.put(`${api2}/sections/${id}/items/${itemId}`, data);
    },
    removeSectionItemEndowmentV2(context, { id, itemId }) {
      return axios.delete(`${api2}/sections/${id}/items/${itemId}`).then((res) => {
        context.dispatch('supportFunction', res.data);
      });
    },
  },
  mutations: {
    GET_ENDOWMENT: (state, payload) => {
      state.endowment = payload;
      return state.endowment;
    },
    GET_ENDOWMENTTYPES: (state, payload) => {
      state.endowmentTypes = payload;
      return state.endowmentTypes;
    },
  },
  getters: {
    endowment(state) {
      return state.endowment;
    },
    endowmentTypes(state) {
      return state.endowmentTypes;
    },
  },
};
