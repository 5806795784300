<template>
  <v-list flat>
    <v-list-item-group>
      <v-list-item
        v-for="item in items"
        :key="item.title"
        link
        :to="`/${item.link}`"
        class="ml-4 mr-6"
        active-class="active font-weight-bold"
      >
        <v-list-item-icon class="my-4 mr-3">
          <v-img
            height="24"
            max-width="24"
            :src="
              getImage(
                $route.path == `/${item.link}` ? `${item.icon}-fill` : item.icon
              )
            "
            class="icon"
          >
          </v-img>
        </v-list-item-icon>
        <v-list-item-content class="py-2" act>
          <v-list-item-title class="f15 black--text">{{
            $t(`menu.${item.title}`)
          }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list-item-group>
  </v-list>
</template>

<script>
export default {
  data() {
    return {
      items: [
        { title: "Performanse", link: "performanse", icon: "perform" },
        { title: "Investment", link: "investment", icon: "file" },
        { title: "Portfolio", link: "portfolio", icon: "wallet" },
        { title: "Settings", link: "settings", icon: "settings" },
      ],
    };
  },
  methods: {
    getImage(icon) {
      return require(`@/assets/icon/${icon}.svg`);
    },
  },
};
</script>

<style lang="scss" scoped>
.active {
  background: rgba(249, 250, 253, 1);
  border-radius: 16px;
  .v-list-item__icon {
    filter: invert(57%) sepia(35%) saturate(899%) hue-rotate(133deg)
      brightness(90%) contrast(87%);
  }
  .v-list-item__title {
    color: var(--v-primary-base) !important;
  }
}
</style>
