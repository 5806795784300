<template>
  <v-card tile width="248" class="card">
    <MenuList />
  </v-card>
</template>

<script>
import MenuList from './MenuList.vue';

export default {
  components: {
    MenuList,
  },
};
</script>

<style scoped>
.card {
  min-height: calc(100vh - 82px);
}
</style>
