import Vue from 'vue';
import Vuex from 'vuex';
import common from './modules/common';
import auth from './modules/auth';
import profile from './modules/profile';
import endowment from './modules/endowment';
import liquidity from './modules/liquidity';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    common,
    auth,
    profile,
    endowment,
    liquidity,
  },
});
