<template>
  <div id="start">
    <v-navigation-drawer
      color="#404072"
      fixed
      overlay-opacity
      temporary
      v-model="drawer"
      width="220"
      class="mobile-menu"
    >
      <div class="logo">
        <v-img
          max-height="58"
          max-width="40"
          contain
          src="@/assets/icon/logo.svg"
          alt="logo"
        ></v-img>
      </div>
      <MenuList />
    </v-navigation-drawer>
    <v-app-bar
      padle
      elevation="0"
      height="82"
      color="accent"
      class="header"
      :class="{
        left: profile.is_endowment_filled && profile.is_wallet_created,
      }"
    >
      <div class="wrap d-flex flex-wrap align-center">
        <v-app-bar-nav-icon @click="drawer = !drawer" class="d-lg-none d-block">
          <v-icon v-if="!drawer">mdi-menu</v-icon>
          <v-icon v-else>mdi-close</v-icon>
        </v-app-bar-nav-icon>
        <router-link to="/">
          <v-img
            max-height="58"
            max-width="40"
            contain
            src="@/assets/icon/logo.svg"
            alt="logo"
          ></v-img>
        </router-link>
        <v-spacer></v-spacer>
        <ul
          class="d-flex align-center"
          v-if="profile.is_endowment_filled && profile.is_wallet_created"
        >
          <li :class="j ? 'ms-10' : ''" v-for="(i, j) in $t('header')" :key="j">
            <router-link
              :to="`/${links[j]}`"
              class="gray--text"
              active-class="black--text active-link d-block"
              exact
            >
              {{ i }}
            </router-link>
          </li>
        </ul>
        <v-spacer></v-spacer>
        <div class="d-flex align-center">
          <router-link
            :to="`/endowment`"
            class="gray--text"
            active-class="black--text active-link d-block"
          >
            {{ $t("headers.endowmentDocument") }}
          </router-link>
          <router-link
            :to="`/wallet`"
            class="gray--text mx-10"
            active-class="black--text active-link d-block"
          >
            {{ $t("headers.wallet") }}
          </router-link>
          <router-link
            :to="`/settings`"
            class="gray--text"
            active-class="black--text active-link d-block"
          >
            {{ $t("headers.settings") }}
          </router-link>
        </div>
        <v-spacer></v-spacer>
        <v-menu offset-y nudge-bottom="22">
          <template v-slot:activator="{ on, attrs, value }">
            <v-btn
              class="d-sm-block d-none"
              :class="`${value ? 'primary' : 'gray'}--text`"
              plain
              v-bind="attrs"
              v-on="on"
              height="44"
            >
              {{ lang.find((i) => i.key == select).name }}
              <v-icon :color="value ? 'primary' : 'gray'">
                {{
                  value
                    ? "mdi-chevron-down"
                    : $vuetify.rtl
                    ? "mdi-chevron-left"
                    : "mdi-chevron-right"
                }}
              </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="item in lang"
              :key="item.key"
              @click="changeLang(item.key)"
            >
              <img :src="flagImg(item.key == 'ar' ? 'sa' : 'us')" alt="flag" />
              <span class="px-5">{{ item.name }}</span>
              <v-icon>{{
                $vuetify.rtl ? "mdi-chevron-left" : "mdi-chevron-right"
              }}</v-icon>
            </v-list-item>
          </v-list>
        </v-menu>
        <div class="text-center me-12">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <div v-bind="attrs" v-on="on">
                <v-list color="accent" class="pa-0">
                  <v-list-item>
                    <v-list-item-avatar
                      size="40"
                      color="gray lighten-3 me-3 rounded-lg"
                    >
                      <v-img
                        v-if="profile.photo != null"
                        :src="profile.photo"
                      ></v-img>
                      <div v-else>
                        <v-icon color="gray">mdi-account mdi-36px</v-icon>
                      </div>
                    </v-list-item-avatar>
                    <v-list-item-title class="black--text font-weight-bold">
                      {{ profile.first_name_en }} {{ profile.family_name_en }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </div>
            </template>
            <v-list>
              <!-- <v-list-item link to="/profile">
                <v-list-item-icon>
                  <v-icon color="gray">mdi-account</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Profile</v-list-item-title>
              </v-list-item> -->
              <v-list-item link @click="logout">
                <v-list-item-icon>
                  <v-icon color="gray">mdi-download mdi-rotate-270</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Sing out</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <v-img
          class="link"
          height="24"
          max-width="24"
          contain
          src="@/assets/icon/bell.svg"
        ></v-img>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
import MenuList from "./MenuList.vue";

export default {
  components: {
    MenuList,
  },
  data() {
    return {
      drawer: false,
      links: ["waqf", "wallet", "settings"],
      lang: [
        { name: "English", key: "en" },
        { name: "Arabic", key: "ar" },
      ],
    };
  },
  mounted() {
    this.$store.dispatch("getProfile");
    this.checkLocalization();
  },
  methods: {
    async logout() {
      await this.$store.dispatch("logout");
    },
    flagImg(code) {
      return require(`@/assets/icon/flags/img_flag_${code}.png`);
    },
    checkLocalization() {
      this.axios.defaults.headers.common["Accept-Language"] =
        localStorage.getItem("lang") ? localStorage.getItem("lang") : "ar";
      this.$i18n.locale = localStorage.getItem("lang")
        ? localStorage.getItem("lang")
        : "ar";
      this.$vuetify.rtl = localStorage.getItem("lang")
        ? localStorage.getItem("lang") == "ar"
        : true;
    },
    changeLang(item) {
      this.axios.defaults.headers.common["Accept-Language"] = item;
      this.$i18n.locale = item;
      this.$vuetify.rtl = item == "ar" ? true : false;
      localStorage.setItem("lang", item);
      this.$store.dispatch("setLang", item);
    },
  },
  computed: {
    profile() {
      return this.$store.getters.profile;
    },
    select() {
      return this.$store.getters.langValue;
    },
  },
};
</script>

<style>
.header.left .v-toolbar__content {
  width: calc(100% - 220px);
  margin-left: auto;
}
.header .v-toolbar__content {
  padding: 0;
}
.mobile-menu {
  top: 64px !important;
  height: calc(100% - 64px) !important;
}
@media screen and (max-width: 1263px) {
  .header .v-toolbar__content {
    width: initial;
    margin-left: initial;
  }
}
</style>
