import '@/plugins/axios';
import i18n from '@/i18n';

const state = {
  data: {},
  errors: [],
};

const mutations = {
  changeLiquidityField(state, payload) {
    state.data[payload.name] = payload.value;
  },
  resetLiquidityData(state, payload) {
    state.data = {
      type: payload,
    };
  },
  setLiquidity(state, payload) {
    if (payload.type) {
      Object.entries(payload).forEach(([key, value]) => {
        state.data[key] = value;
      });
    }
  },
  setLiquidityErrors(state, payload) {
    state.errors = payload;
  },
};

const actions = {
  updateLiquidity(context) {
    return axios
      .post('/customer/api/v2/endowment/liquidity', context.state.data)
      .then(() => {
        context.commit('setLiquidityErrors', []);
        return true;
      })
      .catch((err) => {
        context.commit('setLiquidityErrors', err.response.data.error);
        return false;
      });
  },
};

const getters = {
  liquidityErrors({ errors }) {
    const result = [];

    errors.includes('land_number__required') && result.push(i18n.t('inputError.landNumberRequired'));
    errors.includes('land_number__invalid') && result.push(i18n.t('inputError.landNumberInvalid'));
    errors.includes('block__required') && result.push(i18n.t('inputError.blockRequired'));
    errors.includes('block__invalid') && result.push(i18n.t('inputError.blockInvalid'));
    errors.includes('district__required') && result.push(i18n.t('inputError.districtRequired'));
    errors.includes('district__invalid') && result.push(i18n.t('inputError.districtInvalid'));
    errors.includes('city__required') && result.push(i18n.t('inputError.cityRequired'));
    errors.includes('city__invalid') && result.push(i18n.t('inputError.cityInvalid'));
    errors.includes('deed_number__required') && result.push(i18n.t('inputError.deedNumberRequired'));
    errors.includes('deed_number__invalid') && result.push(i18n.t('inputError.deedNumberInvalid'));
    errors.includes('deed_history__required') && result.push(i18n.t('inputError.deedHistoryRequired'));
    errors.includes('deed_history__invalid') && result.push(i18n.t('inputError.deedHistoryInvalid'));
    errors.includes('total_space__required') && result.push(i18n.t('inputError.totalSpaceRequired'));
    errors.includes('total_space__invalid') && result.push(i18n.t('inputError.totalSpaceInvalid'));
    errors.includes('account_number__required') && result.push(i18n.t('inputError.accountNumberRequired'));
    errors.includes('account_number__invalid') && result.push(i18n.t('inputError.accountNumberInvalid'));
    errors.includes('bank_name__required') && result.push(i18n.t('inputError.bankNameRequired'));
    errors.includes('bank_name__invalid') && result.push(i18n.t('inputError.bankNameInvalid'));
    errors.includes('ratio__required') && result.push(i18n.t('inputError.ratioRequired'));
    errors.includes('ratio__invalid') && result.push(i18n.t('inputError.ratioInvalid'));
    errors.includes('gold_karat__required') && result.push(i18n.t('inputError.goldKaratRequired'));
    errors.includes('gold_karat__invalid') && result.push(i18n.t('inputError.goldKaratInvalid'));
    errors.includes('number_of_ounces__required') && result.push(i18n.t('inputError.numberOfOuncesRequired'));
    errors.includes('number_of_ounces__invalid') && result.push(i18n.t('inputError.numberOfOuncesInvalid'));
    errors.includes('weight_grams__required') && result.push(i18n.t('inputError.weightGramsRequired'));
    errors.includes('weight_grams__invalid') && result.push(i18n.t('inputError.weightGramsInvalid'));
    errors.includes('company_name__required') && result.push(i18n.t('inputError.companyNameRequired'));
    errors.includes('company_name__invalid') && result.push(i18n.t('inputError.companyNameInvalid'));
    errors.includes('cr_number__required') && result.push(i18n.t('inputError.crNumberRequired'));
    errors.includes('cr_number__invalid') && result.push(i18n.t('inputError.crNumberInvalid'));
    errors.includes('capital__required') && result.push(i18n.t('inputError.capitalRequired'));
    errors.includes('capital__invalid') && result.push(i18n.t('inputError.capitalInvalid'));
    errors.includes('company_hq__required') && result.push(i18n.t('inputError.companyHqRequired'));
    errors.includes('company_hq__invalid') && result.push(i18n.t('inputError.companyHqInvalid'));
    errors.includes('activity__required') && result.push(i18n.t('inputError.activityRequired'));
    errors.includes('activity__invalid') && result.push(i18n.t('inputError.activityInvalid'));
    return result;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
