<template>
  <v-app>
    <component :is="layout">
      <router-view />
    </component>
  </v-app>
</template>

<script>
import EmptyLayout from "@/layouts/EmptyLayout";
import MainLayout from "@/layouts/MainLayout";

export default {
  computed: {
    layout() {
      return (this.$route.meta.layout || "empty") + "-layout";
    },
  },
  components: {
    EmptyLayout,
    MainLayout,
  },
};
</script>

<style lang="scss">
#app {
  * {
    font-family: "Frutiger", sans-serif !important;
    letter-spacing: 0 !important;
    // line-height: 1.4 !important;
  }
  .v-radio {
    align-items: start;
  }
  .v-btn--disabled {
    background-color: var(--v-gray-base) !important;
    .v-btn__content {
      color: white;
    }
  }
  .arabic-black {
    font-family: "Frutiger Black", sans-serif !important;
  }

  .arabic-bold {
    font-family: "Frutiger Bold", sans-serif !important;
  }

  .arabic-light {
    font-family: "Frutiger Light", sans-serif !important;
  }

  .arabic-default {
    font-family: "Frutiger", sans-serif !important;
  }
  .v-picker--date {
    .v-btn--disabled {
      background-color: var(--v-gray-lighten2) !important;
    }
  }
}
ul {
  list-style: none;
}
@font-face {
  font-family: "Frutiger";
  src: url("./assets/fonts/FrutigerLTArabicNormal.ttf") format("truetype");
}

@font-face {
  font-family: "Frutiger Bold";
  src: url("./assets/fonts/FrutigerLTArabicBold.ttf") format("truetype");
}

@font-face {
  font-family: "Frutiger Light";
  src: url("./assets/fonts/FrutigerLTArabicLight.ttf") format("truetype");
}

@font-face {
  font-family: "Frutiger Black";
  src: url("./assets/fonts/FrutigerltarabicBlack.ttf") format("truetype");
}
body,
#app {
  background: #f7f9fc;
}
.view-without-scroll {
  overflow: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
}
.view-without-scroll::-webkit-scrollbar {
  width: 0;
  background-color: transparent;
}
.clip {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
a {
  text-decoration: none;
}
.f14 {
  font-size: 14px;
}
.f15 {
  font-size: 15px;
}
.f18 {
  font-size: 18px;
}
.f22 {
  font-size: 22px;
}
.f24 {
  font-size: 24px;
}
.f27 {
  font-size: 27px;
}
.f28 {
  font-size: 28px;
}
.f30 {
  font-size: 30px;
}
.f36 {
  font-size: 36px;
}
.radius0 {
  border-radius: 0 !important;
}
.radius8 {
  border-radius: 8px !important;
}
.radius10 {
  border-radius: 10px !important;
}
.radius16 {
  border-radius: 16px !important;
}
.v-dialog--active,
.radius24 {
  border-radius: 24px !important;
}
.wrap {
  max-width: 1512px;
  width: 100%;
  padding: 0 24px;
  margin: 0 auto;
}
.wrap-medium {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}
.width100 {
  width: 100%;
}
.height100 {
  height: 100%;
}
.link {
  cursor: pointer;
}
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 68px;
}
.active-link {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    bottom: -29px;
    left: 0;
    background: var(--v-primary-base);
    border-radius: 2px 2px 0px 0px;
  }
}
.btn-underline {
  position: relative;
  width: fit-content;
  &::after {
    content: "";
    position: absolute;
    display: block;
    height: 1px;
    width: 100%;
    background: var(--v-secondary-base);
  }
  &:hover:after {
    display: none;
  }
}
.v-btn__content {
  text-transform: none;
  opacity: 1 !important;
  line-height: 1rem;
}
.btn-shadow {
  box-shadow: 1px 2px 2px rgba(0, 0, 0, 0.08);
}
// .v-text-field--outlined fieldset {
//   top: -10px !important;
// }
::placeholder {
  color: var(--v-gray-base) !important;
}
.input-center input {
  text-align: center;
  font-size: 18px;
  padding: 0;
}
.arrow-start {
  position: fixed;
  bottom: 20px;
  right: 8%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 62px;
  height: 62px;
  border-radius: 50% !important;
  background: var(--v-primary-base);
  z-index: 2;
}
.error-border {
  border: 1px solid var(--v-error-base) !important;
}
.gray-border {
  border: 1px solid #ebeff4 !important;
}

@media screen and (max-width: 960px) {
  .arrow-start {
    right: 16px;
  }
  .wrap-main {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.nav-link {
  background-color: transparent;
}

.bg-white {
  background-color: white;
}

.overflow--hidden {
  overflow: hidden;
}

.loader--center {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.relative {
  position: relative;
}

.v-application p {
  margin-bottom: 0;
}

.icon--primary {
  filter: brightness(0) saturate(100%) invert(57%) sepia(70%) saturate(481%)
    hue-rotate(133deg) brightness(84%) contrast(86%);
}

.btn-close {
  position: absolute;
  right: 40px;
  top: 40px;
}

.account .v-dialog,
.security .v-dialog {
  border-radius: 24px !important;
}
</style>
