<template>
  <div class="main-layout" :key="key">
    <Header />
    <v-divider></v-divider>
    <div class="d-flex" :class="{ 'flex-row-reverse': $i18n.locale == 'ar' }">
      <notifications></notifications>
      <Menu v-if="profile.is_endowment_filled && profile.is_wallet_created" class="d-none d-lg-block" />
      <router-view class="content-view" />
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header';
import Menu from '@/components/Menu';

export default {
  components: {
    Header,
    Menu,
  },
  data() {
    return {
      key: 1,
    };
  },
  watch: {
    lang() {
      this.key = ++this.key;
    },
  },
  computed: {
    profile() {
      return this.$store.getters.profile;
    },
    lang() {
      return this.$store.getters.langValue;
    },
  },
};
</script>

<style>
.main-layout .content-view {
  padding-top: 24px;
  padding-bottom: 40px;
  padding-left: 40px;
  padding-right: 40px;
  max-width: 1512px;
  width: 100%;
  margin: 0 auto;
  min-height: calc(100vh - 83px);
}
@media screen and (max-width: 999px) {
  .main-layout .content-view {
    padding-top: 20px;
    padding-left: 40px;
    padding-right: 40px;
  }
}
</style>
