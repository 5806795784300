<template>
  <div class="empty-layout my-sm-8 mx-sm-10 ma-4">
    <notifications></notifications>
    <v-menu offset-y nudge-bottom="10">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="lang-switcher white--text primary pe-4 ps-6"
          plain
          v-bind="attrs"
          v-on="on"
          width="160"
          height="44"
        >
          <div class="d-flex align-center width100">
            <img src="@/assets/icon/globe.svg" alt="globe" class="me-4" />
            <span class="text-body-1">{{ lang.find((i) => i.key == $i18n.locale).name }}</span>
            <v-spacer></v-spacer>
            <img src="@/assets/icon/arrow-select.svg" alt="arrow" />
          </div>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          v-for="item in lang"
          :key="item.key"
          @click="changeLang(item.key)"
          class="px-6"
        >
          <img width="24" height="24" class="rounded-circle" :src="flagImg(item.key == 'ar' ? 'sa' : 'us')" alt="flag" />
          <span class="ps-5">{{ item.name }}</span>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-fab-transition>
      <router-view />
    </v-fab-transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      lang: [
        { name: "English", key: "en" },
        { name: "Arabic", key: "ar" },
      ],
    };
  },
  mounted() {
    this.checkLocalization();
  },
  methods: {
    flagImg(code) {
      return require(`@/assets/icon/flags/img_flag_${code}.png`);
    },
    checkLocalization() {
      this.axios.defaults.headers.common["Accept-Language"] =
        localStorage.getItem("lang") ? localStorage.getItem("lang") : "ar";
      this.$i18n.locale = localStorage.getItem("lang")
        ? localStorage.getItem("lang")
        : "ar";
      this.$vuetify.rtl = localStorage.getItem("lang")
        ? localStorage.getItem("lang") == "ar"
        : true;
    },
    changeLang(item) {
      this.axios.defaults.headers.common["Accept-Language"] = item;
      this.$i18n.locale = item;
      this.$vuetify.rtl = item == "ar" ? true : false;
      localStorage.setItem("lang", item);
      this.$store.dispatch("setLang", item);
    },
  },
};
</script>

<style lang="scss">
.empty-layout {
  min-height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  background: var(--v-accent-base);
  .lang-switcher {
    position: fixed;
    top: 24px;
    right: 24px;
    z-index: 100;
  }
  @media screen and (max-width: 599px) {
    min-height: calc(100vh - 32px);
  }
}
.v-application--is-rtl {
  .empty-layout {
    .lang-switcher {
      right: initial;
      left: 24px;
    }
  }
}
</style>
