import Vue from 'vue';
import './plugins/axios';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import VieMeta from 'vue-meta';
import titlePlugin from '@/plugins/title.plugin';
import i18n from './i18n';
import Vuelidate from 'vuelidate';
import Notifications from '@/components/NotificationPlugin';
import VHijriDatePicker from 'vuetify-umalqura';

Vue.config.productionTip = false;

Vue.use(VieMeta);
Vue.use(titlePlugin);
Vue.use(Vuelidate);
Vue.use(Notifications);
Vue.component(VHijriDatePicker.name, VHijriDatePicker);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
