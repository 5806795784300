import "@/plugins/axios";
import router from "@/router/index";

const api = "/customer/api/v1/";

export default {
  state: {},
  actions: {
    logout(context) {
      return axios.post(`${api}sign-out`).then(() => {
        sessionStorage.clear();
        router.push("/sign-in");
      });
    },
    signIn(context, payload) {
      return axios.post(`${api}sign-in/phone`, payload);
    },
    signInId(context, payload) {
      return axios.post(`${api}sign-in/id`, payload);
    },
    signInOtp(context, payload) {
      return axios.post(`${api}sign-in/otp`, payload);
    },
    otpResend(context, payload) {
      return axios.post(`${api}otp-resend`, payload);
    },
    signUp(context, payload) {
      return axios.post(`${api}sign-up`, payload);
    },
    signUpOtp(context, payload) {
      return axios.post(`${api}sign-up/otp`, payload);
    },
    refresh() {
      return axios
        .post(
          `${api}refresh`,
          { refresh_token: sessionStorage.getItem("accessToken") },
          {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem("accessToken")}`,
            },
          }
        )
        .then((res) => {
          sessionStorage.setItem("accessToken", res.data.access_token);
          router.go(0);
        })
        .catch((e) => {
          router.push("/sign-in");
          sessionStorage.removeItem("accessToken");
        });
    },
    passResetRequest(context, payload) {
      return axios.post(`${api}password-reset-request`, payload);
    },
    passReset(context, payload) {
      return axios.post(`${api}password-reset`, payload);
    },
    confirmEmail(context, payload) {
      return axios.post(`${api}confirm-email`, payload);
    },
  },
};
