import '@/plugins/axios';

const api = '/api/v1/';

export default {
  state: {
    langValue: localStorage.getItem('lang') ? localStorage.getItem('lang') : 'ar',
    phoneCountry: [],
    error: [],
  },
  actions: {
    setLang: (context, payload) => {
      context.commit('GET_LANG', payload);
    },
    getPhoneCountry(context) {
      return axios.get(`${api}allowed-country-codes/`).then((res) => {
        context.commit('GET_PHONECOUNTRY', res.data);
      });
    },
    setPhoneCountry(context) {
      context.commit('GET_PHONECOUNTRY', []);
    },
    setError: (context, payload) => {
      context.commit('GET_ERROR', payload);
    },
  },
  mutations: {
    GET_LANG: (state, payload) => {
      state.langValue = payload;
      return state.langValue;
    },
    GET_PHONECOUNTRY: (state, payload) => {
      state.phoneCountry = payload;
      return state.phoneCountry;
    },
    GET_ERROR: (state, payload) => {
      state.error = payload;
      return state.error;
    },
  },
  getters: {
    langValue(state) {
      return state.langValue;
    },
    phoneCountry(state) {
      return state.phoneCountry;
    },
    error(state) {
      return state.error;
    },
  },
};
